import React, { createContext, useContext, useEffect, useState } from "react";
import apiClientAuth from "services/AuthService";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [routeAccess, setRouteAccess] = useState([]);
  const [error, setError] = useState(null);

  const getUser = async () => {
    await apiClientAuth
      .get('/api').then((resp) => {
        setUser(resp.data);
        setRouteAccess(resp.data.access);
        sessionStorage.setItem("@App:user", JSON.stringify(resp.data));
        sessionStorage.setItem("@App:access", resp.data.access);
      });
  }

  const Login = async ({username, password}) => {
    await apiClientAuth
      .post("/login_check", {
        username: username,
        password: password,
      })
      .then((resp) => {
        // setUser(user);
        // setRouteAccess(JSON.parse(access))
        apiClientAuth.defaults.headers.Authorization = `Bearer ${resp.data.token}`;
        // /** Armazenar o token na sessão */
        // sessionStorage.setItem("@App:user", JSON.stringify(user));
        // sessionStorage.setItem("@App:access", access);
        sessionStorage.setItem("@App:token", resp.data.token);

        getUser();
      })
      .catch((error) => {
        // handle error
        setError(error.message);
      });
  };

  const Logout = () => {
    setUser(null);
    delete apiClientAuth.defaults.headers.Authorization;
    sessionStorage.removeItem("@App:user");
    sessionStorage.removeItem("@App:access");
    sessionStorage.removeItem("@App:token");
  };

  /** Recuperando o usuário e token da sessão */
  useEffect(() => {
    const storagedUser = sessionStorage.getItem("@App:user");
    const storagedToken = sessionStorage.getItem("@App:token");
   
    if (storagedToken && storagedUser) {
      setUser(JSON.parse(storagedUser));
      apiClientAuth.defaults.headers.Authorization = `Bearer ${storagedToken}`;
    }
  }, []);

  const getToken = () => {
    return sessionStorage.getItem("@App:token");
  }

  return (
    <AuthContext.Provider
      value={{ signed: Boolean(user), user, routeAccess, error, Login, Logout, getToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

export default AuthContext;
