import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { useAuth } from "contexts/AuthContext";

export const AppViews = () => {

  const { user } = useAuth();

  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />
        <Route path={`${APP_PREFIX_PATH}/faturamento`} component={lazy(() => import(`./faturamento`))} />
        <Route path={`${APP_PREFIX_PATH}/contas-a-pagar`} component={lazy(() => import(`./bill`))} />
        <Route path={`${APP_PREFIX_PATH}/curriculum`} component={lazy(() => import(`./curriculum`))} />
        <Route path={`${APP_PREFIX_PATH}/relatorios`} component={lazy(() => import(`./relatorios`))} />
        <Route path={`${APP_PREFIX_PATH}/apps`} component={lazy(() => import(`./apps`))} />
        <Route path={`${APP_PREFIX_PATH}/components`} component={lazy(() => import(`./components`))} />
        <Route path={`${APP_PREFIX_PATH}/pages`} component={lazy(() => import(`./pages`))} />
        <Route path={`${APP_PREFIX_PATH}/maps`} component={lazy(() => import(`./maps`))} />
        <Route path={`${APP_PREFIX_PATH}/charts`} component={lazy(() => import(`./charts`))} />
        <Route path={`${APP_PREFIX_PATH}/docs`} component={lazy(() => import(`./docs`))} />
        { user.access === 'faturamento' ? <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/faturamento/list`} /> : null }
        { user.access === 'contas-a-pagar' ? <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/bill/list`} /> : null }
        { user.access === 'recruiter' ? <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/curriculum/list`} /> : null }
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);
